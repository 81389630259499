import React, { useContext } from 'react'
import Layout from '../../../components/customlayout'

import AuthContext from "../../../context/AuthContext"

import Guilds from "../../../components/guild"

const DashboardMusicPage = () => {
    const { isLogged, me, refreshMutual } = useContext(AuthContext);
    if (!me.mutualGuilds) refreshMutual();
    return (
        <Layout>
            <main className='container py-4'>
                you found the easter egg
            </main>
        </Layout>
    )
}

export default DashboardMusicPage